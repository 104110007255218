import './App.css';
import './styles.css';

import { useContext, useState, createContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LogoHeader from './LogoHeader';
import HowItWorks from './HowItWorks';
import SwapLocations from './SwapLocations';
import Register from './Register';
import SignIn from './SignIn';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Home from './Home';
import Navbar from './NavBar';
import Account from './Account';
import Footer from './Footer';
import BurgerMenu from './BurgerMenu';
import SearchBar from './SearchBar';
import { Outlet } from "react-router-dom"
import LandingPage from './LandingPage';
import GoogleAnalytics from './GoogleAnalytics'


export const ClickContext = createContext();

function App() { 
  // [State variable, updater function] = useState(default value)
  const [onClick, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!onClick);
  };

  const [onSearchClick, setIsSearchClicked] = useState(false);

  const handleSearchClick = () => {
    setIsSearchClicked(!onSearchClick);
  }
  
  const HomeLayout = () => {
    const { onClick, onSearchClick} = useContext(ClickContext);
  
    return (
      <div className="flex flex-col h-screen">
        <LogoHeader />
        {onClick && <BurgerMenu />}
        {onSearchClick && <SearchBar />}
        {!onClick && (
          <>
            <Navbar />
            <Outlet />
            <Footer />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <ClickContext.Provider value={{handleClick, onClick, handleSearchClick, onSearchClick}}>
          <GoogleAnalytics />
          <Routes>
            <Route path="Register" element={<Register />}/>
            <Route path="SignIn" element={<SignIn />}/>
            <Route path="/" element={<LandingPage />}/>
            
            <Route path="/" element={<HomeLayout />}>
            {!(onClick || onSearchClick) && (
              <> 
              <Route path="/" element={<Home />} />
              <Route path="Account" element={<Account />} />
              <Route path="HowItWorks" element={<HowItWorks />} />
              {/* <Route path="/MakeAListing" element={<MakeAListing />} /> */}
              <Route path="AboutUs" element={<AboutUs />} />
              <Route path="ContactUs" element={<ContactUs />} />
              </>
              )}
            </Route>
          </Routes>
        </ClickContext.Provider>  
      </BrowserRouter>
    </div>
  );
}

export default App;
