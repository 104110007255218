import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    const initializeAnalytics = () => {
      const script1 = document.createElement('script');
      script1.src = `https://www.googletagmanager.com/gtag/js?id=G-0CKEYXHJ9C`;
      script1.async = true;

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-0CKEYXHJ9C');
      `;

      document.head.appendChild(script1);
      document.head.appendChild(script2);

      return () => {
        document.head.removeChild(script1);
        document.head.removeChild(script2);
      };
    };

    const checkConsent = () => {
      if (window.CookieConsent && window.CookieConsent.hasConsented('statistics')) {
        initializeAnalytics();
      } else {
        window.addEventListener('CookieConsent', (event) => {
          if (event.detail.hasConsented('statistics')) {
            initializeAnalytics();
          }
        });
      }
    };

    checkConsent();
  }, []);

  return null;
};

export default GoogleAnalytics;
